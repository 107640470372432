<template>
  <CustomDrawer
    ref="CustomDrawer"
    :title="title"
    :show-footer="formStatus !== 'detail'"
    @openedCallback="openedCallback"
    @handleConfirm="handleConfirm"
  >
    <CustomForm
      ref="CustomForm"
      :form-model="formModel"
      :form-items="formItem"
      :form-rules="formRules"
    >
      <UploadImg slot="photoId" slot-scope="{item}" :upload-lists="photoUploadLists" :pic-disable="formStatus === 'detail'" @uploadChange="item.uploadChange" />
      <RegionForm slot="tenant" ref="RegionForm" v-model="formModel.tenant" :disabled="formStatus === 'detail'" />
    </CustomForm>
  </CustomDrawer>
</template>

<script>
import CustomDrawer from '@/components/CustomDrawer/index'
import CustomForm from '@/components/CustomForm/index'
import { mapActions } from 'vuex'
import RegionForm from '@/components/RegionForm'
import UploadImg from '@/components/UploadImg'
export default {
  name: 'Form',
  components: { UploadImg, RegionForm, CustomDrawer, CustomForm },
  data() {
    return {
      title: '',
      formId: '',
      formStatus: '',
      photoUploadLists: [],
      formModel: {
        title: '',
        photoId: '',
        detail: '',
        tenant: [],
        address: '',
        contactPerson: '',
        contactPhone: '',
        credits: 0,
        stocks: 0,
        time: [],
        startTime: '',
        endTime: '',
        status: 1
      }
    }
  },
  computed: {
    formItem() {
      return {
        'title': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': '标题',
          'maxlength': 255,
          'disabled': this.formStatus === 'detail'
        },
        'photoId': {
          'elColSpan': 24,
          'label': '图片',
          'slot': 'photoId',
          'uploadChange': data => {
            if (data.length) {
              this.$set(this.formModel, 'photoId', data[0])
            } else {
              this.$set(this.formModel, 'photoId', '')
            }
          }
        },
        'tenant': {
          'elColSpan': 12,
          'label': '服务区域',
          'slot': 'tenant'
        },
        'address': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '兑换地址',
          'maxlength': 255,
          'disabled': this.formStatus === 'detail'
        },
        'contactPerson': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '负责人',
          'maxlength': 100,
          'disabled': this.formStatus === 'detail'
        },
        'contactPhone': {
          'elColSpan': 12,
          'component': 'CustomFormText',
          'label': '联系方式',
          'maxlength': 100,
          'disabled': this.formStatus === 'detail'
        },
        'credits': {
          'elColSpan': 12,
          'component': 'CustomFormNumber',
          'label': '所需积分',
          'min': 0,
          'max': 99999999,
          'precision': 0,
          'step': 10,
          'controls': true,
          'disabled': this.formStatus === 'detail'
        },
        'stocks': {
          'elColSpan': 12,
          'component': 'CustomFormNumber',
          'label': '库存',
          'min': 0,
          'max': 99999999,
          'precision': 0,
          'step': 10,
          'controls': true,
          'disabled': this.formStatus === 'detail'
        },
        'time': {
          'elColSpan': 24,
          'component': 'CustomFormDatePicker',
          'label': '有效期',
          'type': 'datetimerange',
          'valueFormat': 'yyyy-MM-dd HH:mm:ss',
          'disabled': this.formStatus === 'detail'
        },
        'detail': {
          'elColSpan': 24,
          'component': 'CustomFormTinymce',
          'label': '介绍',
          'disabled': this.formStatus === 'detail'
        },
        'status': {
          'elColSpan': 24,
          'component': 'CustomFormRadio',
          'label': '状态',
          'data': ['下架', '正常'],
          'disabled': this.formStatus === 'detail'
        }
      }
    },
    formRules() {
      return {
        title: [
          { required: true, message: '标题不能为空', trigger: ['blur', 'change'] }
        ],
        photoId: [
          { required: true, message: '图片不能为空', trigger: ['blur', 'change'] }
        ],
        tenant: [
          { required: true, message: '服务区域不能为空', trigger: ['blur', 'change'] }
        ],
        address: [
          { required: true, message: '兑换地址不能为空', trigger: ['blur', 'change'] }
        ],
        contactPerson: [
          { required: true, message: '负责人不能为空', trigger: ['blur', 'change'] }
        ],
        contactPhone: [
          { required: true, message: '联系方式不能为空', trigger: ['blur', 'change'] },
          {
            validator: (rule, value, callback) => {
              if (value && !this.$regex.telRegEx(value)) {
                callback(new Error('联系方式格式不正确'))
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        credits: [
          { required: true, message: '所需积分不能为空', trigger: ['blur', 'change'] }
        ],
        stocks: [
          { required: true, message: '库存不能为空', trigger: ['blur', 'change'] }
        ],
        time: [
          { required: true, message: '有效期不能为空', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['goodsPublicDetail', 'goodsAdd', 'goodsUpdate']),
    handleAdd() {
      this.init('add')
    },
    handleEdit(id) {
      this.init('edit', id)
    },
    handleDetail(id) {
      this.init('detail', id)
    },
    init(formStatus, id = '') {
      Object.assign(this, this.$options.data.call(this))
      this.$refs.CustomDrawer.handleOpen()
      this.formId = id
      this.formStatus = formStatus
      switch (formStatus) {
        case 'add':
          this.title = '添加'
          break
        case 'edit':
          this.title = '编辑'
          break
        case 'detail':
          this.title = '查看'
          break
        default:
          this.title = '未知'
          break
      }
    },
    openedCallback() {
      this.initData().then(() => {
        this.$nextTick(() => {
          this.$refs.RegionForm.initRegion()
        })
        this.$refs.CustomForm.$refs.form.clearValidate()
        this.$refs.CustomDrawer.closeLoading()
      }).catch(() => {
        this.$refs.CustomDrawer.handleClose()
      })
    },
    initData() {
      return new Promise((resolve, reject) => {
        if (this.formStatus !== 'add') {
          this.goodsPublicDetail({ id: this.formId }).then(res => {
            if (res.code !== 200) {
              this.$message.error(res.msg)
              reject()
              return
            }

            this.photoUploadLists = [
              {
                'id': res.data.photoId,
                'url': res.data.dfsUrl
              }
            ]

            this.formModel.id = this.formId
            this.formModel.title = res.data.title
            this.formModel.photoId = res.data.photoId
            this.formModel.detail = res.data.detail
            this.formModel.tenant = res.data.tenant.split(',').slice(2)
            this.formModel.address = res.data.address
            this.formModel.contactPerson = res.data.contactPerson
            this.formModel.contactPhone = res.data.contactPhone
            this.formModel.credits = res.data.credits
            this.formModel.stocks = res.data.stocks
            this.formModel.time = [res.data.startTime, res.data.endTime]
            this.formModel.status = res.data.status
            resolve()
          }).catch(() => {
            reject()
          })
        } else {
          resolve()
        }
      })
    },
    handleConfirm() {
      this.$refs.CustomDrawer.openLoading()
      this.$refs.CustomForm.$refs.form.validate(res => {
        if (res) {
          const params = { ...this.formModel }
          params.tenant = `1,101,${params.tenant.join(',')}`
          params.startTime = params.time[0]
          params.endTime = params.time[1]
          delete params.time

          switch (this.formStatus) {
            case 'add':
              this.goodsAdd(params).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('添加成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            case 'edit':
              this.goodsUpdate(params).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('修改成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            default:
              this.$refs.CustomDrawer.handleClose()
              break
          }
        } else {
          this.$refs.CustomDrawer.closeLoading()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
