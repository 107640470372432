<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="searchStatus" status="my-orange" @click="searchStatus = false">隐藏查询</vxe-button>
        <vxe-button v-if="!searchStatus" status="my-orange" icon="fa fa-search" @click="searchStatus = true">查询</vxe-button>
        <vxe-button v-permission="'GoodsGoodsAddButton'" status="my-orange" icon="fa fa-plus" @click="handleAdd()">添加</vxe-button>
      </template>
    </vxe-toolbar>
    <div v-if="searchStatus" class="search">
      <vxe-form title-align="right" title-width="120" title-colon :data="listQuery" @submit="handleSearch(true)" @reset="handleSearch(true, true)">
        <vxe-form-item title="标题" span="6">
          <vxe-input v-model="listQuery.param.title" placeholder="请输入标题" clearable />
        </vxe-form-item>
        <vxe-form-item title="状态" span="6">
          <vxe-select v-model="listQuery.param.status" placeholder="请选择状态" clearable>
            <vxe-option
              v-for="(item, index) in statusArray"
              :key="index"
              :label="item"
              :value="index"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <CustomList ref="CustomList" :columns="tableColumns" :list-query="listQuery" @getList="getList">
      <CustomPic slot="dfsUrl" slot-scope="{row}" :src="row.dfsUrl || ''" :src-list="[row.dfsUrl]" />
      <template slot="operating" slot-scope="{row}">
        <el-button v-permission="'GoodsGoodsCheckButton'" size="small" type="text" @click="handleDetail(row.id)">查看</el-button>
        <el-button v-permission="'GoodsGoodsEditButton'" size="small" type="text" @click="handleEdit(row.id)">编辑</el-button>
        <el-button v-permission="'GoodsExchangeRecord'" size="small" type="text" @click="goGoodsExchangeRecord(row.id)">兑换记录</el-button>
      </template>
    </CustomList>
    <Form ref="Form" @submit="handleSearch()" />
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
import Form from './components/form'
export default {
  name: 'Goods',
  components: { Form, CustomList, ...CustomTemplate },
  data() {
    return {
      searchStatus: false,
      statusArray: ['下架', '正常'],
      listQuery: {
        param: {
          hasTenant: true
        }
      },
      tableColumns: [
        {
          prop: 'title',
          title: '标题',
          minWidth: 200
        },
        {
          title: '图片',
          slot: 'dfsUrl'
        },
        {
          prop: 'address',
          title: '兑换地址',
          minWidth: 150
        },
        {
          title: '负责人信息',
          formatter: (row, column) => {
            let text = row.contactPerson || ''
            text += row.contactPerson && row.contactPhone ? ' <br/> ' : ''
            text += row.contactPhone || ''
            return text
          },
          minWidth: 150
        },
        {
          prop: 'credits',
          title: '所需积分',
          minWidth: 100
        },
        {
          prop: 'stocks',
          title: '库存',
          minWidth: 100
        },
        {
          title: '有效期',
          formatter: (row, column) => {
            return `${row.startTime} <br/> ～ <br/> ${row.endTime}`
          },
          minWidth: 160
        },
        {
          title: '状态',
          formatter: (row, column) => {
            return row.status in this.statusArray ? this.statusArray[row.status] : ''
          },
          minWidth: 100
        },
        {
          title: '操作',
          fixed: 'right',
          slot: 'operating',
          minWidth: 150
        }
      ]
    }
  },
  methods: {
    ...mapActions(['goodsPublicPageList']),
    handleSearch(resetCurrentPage = false, resetSearch = false) {
      if (resetSearch) {
        Object.assign(this.listQuery, this.$options.data.call(this).listQuery)
      }
      this.customListGetList(resetCurrentPage)
    },
    customListGetList(resetCurrentPage = false) {
      this.$refs.CustomList.getList(resetCurrentPage)
    },
    getList(params, callback) {
      this.goodsPublicPageList(params).then(response => {
        callback(response)
      }).catch(() => {})
    },
    handleAdd() {
      this.$refs.Form.handleAdd()
    },
    handleDetail(id) {
      this.$refs.Form.handleDetail(id)
    },
    handleEdit(id) {
      this.$refs.Form.handleEdit(id)
    },
    goGoodsExchangeRecord(goodsId) {
      this.$router.push({ name: 'GoodsExchangeRecord', params: { goodsId }})
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
